function setLocalStorage(name, payload) {
  if (payload !== "String") {
    payload = JSON.stringify(payload);
  }
  localStorage.setItem(name, payload);
}

function getLocalStorage(name) {
  const payload = localStorage.getItem(name);
  return payload;
}

function deleteLocalStorage(name) {
  localStorage.removeItem(name);
}

const helperFunction = {
  setLocalStorage,
  getLocalStorage,
  deleteLocalStorage,
};

export default helperFunction;
