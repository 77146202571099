import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import CustomButton from "../CustomButton";
import "./CustomHeading.less";

const CustomHeading = (props) => {
  const { type, className = "", backButton } = props;
  let headingClass;

  switch (type) {
    case "secondary":
      headingClass = "second-heading";
      break;
    default:
      headingClass = "primary-heading";
      break;
  }

  const navigate = useNavigate();

  return (
    <div className="kl-custom-heading">
      <div className="aside">
        {backButton && (
          <CustomButton
            onClick={() => navigate(-1)}
            icon={<LeftOutlined />}
            className="backBtnCircle"
          ></CustomButton>
        )}
        <span className="kl-custom-heading">
          <h5 {...props} className={`${headingClass} ${className}`}>
            {props.children}
          </h5>
        </span>
      </div>
    </div>
  );
};

export default memo(CustomHeading);
