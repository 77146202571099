import React from "react";

function ProductCatalogIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 11.1667V5.83332C13.9998 5.5995 13.938 5.36986 13.821 5.16743C13.704 4.965 13.5358 4.79689 13.3333 4.67999L8.66667 2.01332C8.46397 1.89629 8.23405 1.83469 8 1.83469C7.76595 1.83469 7.53603 1.89629 7.33333 2.01332L2.66667 4.67999C2.46418 4.79689 2.29599 4.965 2.17897 5.16743C2.06196 5.36986 2.00024 5.5995 2 5.83332V11.1667C2.00024 11.4005 2.06196 11.6301 2.17897 11.8325C2.29599 12.035 2.46418 12.2031 2.66667 12.32L7.33333 14.9867C7.53603 15.1037 7.76595 15.1653 8 15.1653C8.23405 15.1653 8.46397 15.1037 8.66667 14.9867L13.3333 12.32C13.5358 12.2031 13.704 12.035 13.821 11.8325C13.938 11.6301 13.9998 11.4005 14 11.1667Z"
        stroke="#A3A3A3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.18018 5.14001L8.00018 8.50668L13.8202 5.14001"
        stroke="#A3A3A3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 15.22V8.5"
        stroke="#A3A3A3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ProductCatalogIcon;
