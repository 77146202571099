import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tokens: {
    accessToken: null,
    userRole: null,
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, { payload }) => {
      state.tokens.accessToken = payload?.access_token;
      state.tokens.userRole = payload?.userRole;
    },
    logout: (state) => {
      state.tokens.accessToken = null;
      state.tokens.userRole = null;
    },
  },
});

export default authSlice;
