import React, { memo } from "react";
import { Result } from "antd";
import "./CustomResult.less";

const CustomResult = (props) => {
  return (
    <span className="CustomResultWrapper">
      <Result {...props} />
    </span>
  );
};

export default memo(CustomResult);
