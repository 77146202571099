import React from "react";

function BusinessDetailsIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.833 2.75V6.41667C12.833 6.65978 12.9296 6.89294 13.1015 7.06485C13.2734 7.23676 13.5066 7.33333 13.7497 7.33333H17.4163"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.583 19.25H6.41634C5.93011 19.25 5.4638 19.0568 5.11998 18.713C4.77616 18.3692 4.58301 17.9029 4.58301 17.4167V4.58333C4.58301 4.0971 4.77616 3.63079 5.11998 3.28697C5.4638 2.94315 5.93011 2.75 6.41634 2.75H12.833L17.4163 7.33333V17.4167C17.4163 17.9029 17.2232 18.3692 16.8794 18.713C16.5356 19.0568 16.0692 19.25 15.583 19.25Z"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 8.25H9.16667"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 11.9167H13.75"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 15.5833H13.75"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default BusinessDetailsIcon;
