import React from 'react'

function BriefCaseIcon() {
    return (

        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.6667 5.16669H3.33333C2.59695 5.16669 2 5.76364 2 6.50002V12.5C2 13.2364 2.59695 13.8334 3.33333 13.8334H12.6667C13.403 13.8334 14 13.2364 14 12.5V6.50002C14 5.76364 13.403 5.16669 12.6667 5.16669Z" stroke="#A3A3A3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.3335 5.16667V3.83333C5.3335 3.47971 5.47397 3.14057 5.72402 2.89052C5.97407 2.64048 6.31321 2.5 6.66683 2.5H9.3335C9.68712 2.5 10.0263 2.64048 10.2763 2.89052C10.5264 3.14057 10.6668 3.47971 10.6668 3.83333V5.16667" stroke="#A3A3A3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 8.5V8.50727" stroke="#A3A3A3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2 9.16669C3.86105 10.1045 5.91602 10.593 8 10.593C10.084 10.593 12.1389 10.1045 14 9.16669" stroke="#A3A3A3" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default BriefCaseIcon
