// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unAuthorized {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}
#main-content-wrapper .unAuthorized {
  height: 100%;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/screens/UnAuthorized/UnAuthorized.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBAAA;AADF;AAIA;EAEI,YAAA;EACA,WAAA;AAHJ","sourcesContent":["@import url(\"../../assets/Styles/variables.less\");\n\n.unAuthorized {\n  height: 100vh;\n  width: 100vw;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: @white-color;\n}\n\n#main-content-wrapper {\n  .unAuthorized {\n    height: 100%;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
