import { useSelector } from "react-redux";

export const useAuth = () => {
  const accessToken = useSelector(
    (state) => state?.authSlice?.tokens?.accessToken
  );
  const userRole = useSelector((state) => state?.userSlice?.user?.userRole);
  const userObj = useSelector((state) => state?.userSlice?.user);

  return { accessToken, userRole, userObj };
};
