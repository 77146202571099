import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {
    userId: null,
    userRole: null,
    name: null,
    email: null,
    permissions: null,
    business: null,
    shippingAddress: null,
  },
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    me: (state, { payload }) => {
      state.user.userId = payload?.userId;
      state.user.userRole = payload?.userRole;
      state.user.name = payload?.name;
      state.user.email = payload?.email;
      state.user.permissions = payload?.permissions;
      state.user.business = payload?.business;
      state.user.shippingAddress = payload?.shippingAddress;
    },
    logoutMe: (state) => {
      state.user.userId = null;
      state.user.userRole = null;
      state.user.name = null;
      state.user.email = null;
      state.user.permissions = null;
      state.user.business = null;
      state.user.shippingAddress = null;
    },
  },
});

export default userSlice;
