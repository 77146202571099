import { Col } from "antd";
import React, { memo } from "react";

function CustomColumn(props) {
  return (
    <Col {...props} className={`custom-column ${props.className}`}>
      {props.children}
    </Col>
  );
}

export default memo(CustomColumn);
