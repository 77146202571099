import { createSlice } from "@reduxjs/toolkit";

// Get and set cart for specific user
const getUserCartKey = (userId) => `cart_${userId}`;
const getUserTotalKey = (userId) => `total_${userId}`;

const initialState = {
  cart: [], // This will be populated based on the user
  total: 0, // Same for total
  userId: null,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    // Set userId to initialize cart for a specific user
    setUserIdForCart: (state, { payload }) => {
      const { userId } = payload;
      state.userId = userId;
      // Load the specific user's cart and total
      state.cart =
        JSON.parse(localStorage.getItem(getUserCartKey(userId))) || [];
      state.total =
        JSON.parse(localStorage.getItem(getUserTotalKey(userId))) || 0;
    },

    addItemToCart: (state, { payload }) => {
      const existingItem = state.cart.find((item) => item.id === payload.id);
      if (existingItem) {
        if (payload.productQuantity) {
          existingItem.quantity = payload.quantity;
        } else {
          existingItem.quantity += 1;
        }
      } else {
        state.cart.push({
          ...payload,
          quantity: payload.productQuantity ? payload.quantity : 1,
          availableQuantitiy: payload.productQuantity
            ? payload.productQuantity
            : payload.quantity,
        });
      }

      if (!existingItem) {
        state.total += payload.productQuantity
          ? payload.price * payload.quantity
          : payload.price;
      }

      // Save the updated cart and total for the specific user
      localStorage.setItem(
        getUserCartKey(state.userId),
        JSON.stringify(state.cart)
      );
      localStorage.setItem(
        getUserTotalKey(state.userId),
        JSON.stringify(state.total)
      );
    },

    removeItemFromCart: (state, { payload }) => {
      const item = state.cart.find((item) => item.id === payload.id);
      if (item) {
        state.total -= item.price * item.quantity;
        state.cart = state.cart.filter((item) => item.id !== payload.id);
        localStorage.setItem(
          getUserCartKey(state.userId),
          JSON.stringify(state.cart)
        );
        localStorage.setItem(
          getUserTotalKey(state.userId),
          JSON.stringify(state.total)
        );
      }
    },

    updateCartItemQuantity: (state, { payload }) => {
      const { id, quantity } = payload;
      const item = state.cart.find((item) => item.id === id);
      if (item) {
        state.total -= item.price * item.quantity;
        item.quantity = Math.max(1, quantity);
        state.total += item.price * item.quantity;
        localStorage.setItem(
          getUserCartKey(state.userId),
          JSON.stringify(state.cart)
        );
        localStorage.setItem(
          getUserTotalKey(state.userId),
          JSON.stringify(state.total)
        );
      }
    },

    clearCart: (state) => {
      state.cart = [];
      state.total = 0;
      localStorage.setItem(
        getUserCartKey(state.userId),
        JSON.stringify(state.cart)
      );
      localStorage.setItem(
        getUserTotalKey(state.userId),
        JSON.stringify(state.total)
      );
    },
  },
});

export const {
  setUserIdForCart,
  addItemToCart,
  removeItemFromCart,
  updateCartItemQuantity,
  clearCart,
} = cartSlice.actions;

export default cartSlice;
