import React from "react";

function CustomerIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0534 17.3036V16.0813C15.0534 15.433 14.7395 14.8113 14.1808 14.3529C13.6221 13.8944 12.8643 13.6369 12.0742 13.6369H6.11589C5.32576 13.6369 4.568 13.8944 4.0093 14.3529C3.45059 14.8113 3.13672 15.433 3.13672 16.0813V17.3036"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.63672 9.97028C10.1555 9.97028 11.3867 8.73906 11.3867 7.22028C11.3867 5.70149 10.1555 4.47028 8.63672 4.47028C7.11794 4.47028 5.88672 5.70149 5.88672 7.22028C5.88672 8.73906 7.11794 9.97028 8.63672 9.97028Z"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7201 17.3036V16.0543C18.7196 15.5007 18.5394 14.9629 18.2076 14.5253C17.8757 14.0878 17.4111 13.7753 16.8867 13.6369"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1367 4.47028C14.6612 4.62662 15.126 4.98174 15.4579 5.47966C15.7899 5.97757 15.9701 6.58996 15.9701 7.22028C15.9701 7.85059 15.7899 8.46298 15.4579 8.96089C15.126 9.45881 14.6612 9.81393 14.1367 9.97028"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CustomerIcon;
