import "./FullScreenLoader.less";

const FullScreenLoader = (props) => {
  return (
    <div className="preloaderWrapper">
      <div className="preloaderBg">
        <div className="preloader"></div>
        <div className="preloader2"></div>
      </div>
    </div>
  );
};

export default FullScreenLoader;
