// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 780px) {
  .custom-row {
    margin: 0px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/UiComponents/CustomRow/CustomRow.less"],"names":[],"mappings":"AACI;EAAA;IACI,sBAAA;EACN;AACF","sourcesContent":[".custom-row{\n    @media(max-width:780px){\n        margin: 0px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
