import React from "react";

const LogoutIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8333 5.33329V3.49996C10.8333 3.01373 10.6402 2.54741 10.2964 2.2036C9.95254 1.85978 9.48623 1.66663 9 1.66663H2.58333C2.0971 1.66663 1.63079 1.85978 1.28697 2.2036C0.943154 2.54741 0.75 3.01373 0.75 3.49996V14.5C0.75 14.9862 0.943154 15.4525 1.28697 15.7963C1.63079 16.1401 2.0971 16.3333 2.58333 16.3333H9C9.48623 16.3333 9.95254 16.1401 10.2964 15.7963C10.6402 15.4525 10.8333 14.9862 10.8333 14.5V12.6666"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.41666 9H17.25M17.25 9L14.5 6.25M17.25 9L14.5 11.75"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LogoutIcon;
