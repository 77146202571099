import React, { useCallback, useEffect, useRef, useState } from "react";
import { Layout, Menu, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomScrollbars from "components/CustomComponents/CustomScrollbars";
import {
  AllMenusItems,
  authenticatedRoutes,
  permissionsToRoutes,
  unAuthenticatedRoutes,
} from "utils/Constants/routes.constant";
import helperFunction from "utils/Helper/Functions.helper";
import authSlice from "../../redux/Slices/auth.Slice";
import userSlice from "../../redux/Slices/user.Slice";
import useMobileView from "hooks/useMobileView";
import { useAuth } from "hooks/useAuth";
import { USER_TYPE } from "utils/Constants/Constant";
import { clearCart } from "../../redux/Slices/cart.Slice";
import CircleExclamaion from "assets/SvgFiles/CircleExclamaion";
import LogoutIcon from "assets/SvgFiles/LogoutIcon";
import logo from "assets/Images/preloader.png";
import logo2 from "assets/Images/logo-small.png";
import "./Sidebar.less";

const Sidebar = (props) => {
  const { Sider } = Layout;
  const { confirm } = Modal;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const isMobileView = useMobileView();
  const { userObj } = useAuth();
  const { logout } = authSlice.actions;
  const { logoutMe } = userSlice.actions;
  const [collapsed, setCollapsed] = useState(false);
  const sidebarRef = useRef(null);

  // Handle click outside to collapse sidebar
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isMobileView &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target)
      ) {
        setCollapsed(true);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef, isMobileView]);

  const activeMenuItemKey = AllMenusItems.find((item) =>
    location.pathname.startsWith(`/${item.linkTo}`)
  )?.selectedOptionKey;

  useEffect(() => {
    setCollapsed(isMobileView);
  }, [isMobileView]);

  const getMenu = (label, key, icon, children, type, className) => ({
    key,
    icon,
    children,
    label,
    type,
    className: `menu-${key}`,
  });

  // Filtering the menu items based on the permissions
  const filterMenuItemsByPermissions = (menuItems, permissions) => {
    if (!Array.isArray(permissions)) {
      return [];
    }
    return menuItems.filter((menuItem) => {
      const route = menuItem.linkTo;
      // Allow access if no requiredPermissions or an empty array
      const requiredPermissions = permissionsToRoutes[route] || [];
      if (requiredPermissions.length === 0) {
        return true;
      }
      return requiredPermissions.some((permission) =>
        permissions.includes(permission)
      );
    });
  };

  const userPermissions = userObj?.permissions || [];
  const filteredMenuItems = filterMenuItemsByPermissions(
    AllMenusItems,
    userPermissions
  );

  const items = [
    ...filteredMenuItems.map((menu) =>
      getMenu(
        <Link to={menu?.linkTo}>
          {menu?.icon && <span className="menu-icon">{menu.icon}</span>}
          <span className="menu-icon-text">{menu.label}</span>
        </Link>,
        menu.selectedOptionKey
      )
    ),
    getMenu(
      <Link to="#" onClick={() => showPromiseConfirm()}>
        <span className="menu-icon">
          {" "}
          <LogoutIcon />
        </span>
        <span className="menu-icon-text">Logout</span>
      </Link>,
      "logout"
    ),
  ];

  const showPromiseConfirm = () => {
    confirm({
      wrapClassName: "logOutModal",
      title: `Are you sure?`,
      width: "455px",
      centered: "true",
      closable: "true",
      destroyOnClose: "true",
      content: t("areYouSureYouWantToLogout"),
      okText: t("logout"),
      cancelText: t("cancel"),
      icon: (
        <div className="icon-wrapper">
          <CircleExclamaion />
        </div>
      ),
      async onOk() {
        logoutCall();
      },
      async onCancel() {},
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <OkBtn />
          <CancelBtn />
        </>
      ),
    });
  };
  const logoutCall = useCallback(async () => {
    helperFunction.deleteLocalStorage("jwtToken");
    helperFunction.deleteLocalStorage("isLoggedIn");
    // dispatch(clearCart());
    dispatch(logout());
    dispatch(logoutMe());

    navigate(`${unAuthenticatedRoutes.AUTHSCREEN}`);
  }, [dispatch, logout, logoutMe, navigate]);

  return (
    <div className="sidebar-sec" ref={sidebarRef}>
      <Sider
        collapsible
        breakpoint="lg"
        collapsedWidth={isMobileView ? "0px" : "55px"}
        width={isMobileView ? "200px" : "210px"}
        onCollapse={() => setCollapsed(!collapsed)}
        collapsed={collapsed}
        {...props}
      >
        <Link
          to={
            userObj?.userRole === USER_TYPE?.ADMIN
              ? authenticatedRoutes.DASHBOARD
              : authenticatedRoutes.PRODUCT_CATALOGS
          }
          className="left"
        >
          <div className="img">
            {collapsed ? (
              <img src={logo2} alt={t("icon")} />
            ) : (
              <img src={logo} alt={t("icon")} />
            )}
          </div>
        </Link>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            theme="dark"
            className="custom-sidebar"
            selectedKeys={[activeMenuItemKey ? activeMenuItemKey : "dashboard"]}
            mode="inline"
            items={items}
          />
        </CustomScrollbars>
      </Sider>
    </div>
  );
};

export default Sidebar;
