import React from 'react'

function CircleExclamaion() {
    return (
        <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M43 0.625C66.4082 0.625 85.5 19.7168 85.5 43.125C85.5 66.6992 66.4082 85.625 43 85.625C19.4258 85.625 0.5 66.6992 0.5 43.125C0.5 19.7168 19.4258 0.625 43 0.625ZM43 80.3125C63.4199 80.3125 80.1875 63.7109 80.1875 43.125C80.1875 22.7051 63.4199 5.9375 43 5.9375C22.4141 5.9375 5.8125 22.7051 5.8125 43.125C5.8125 63.7109 22.4141 80.3125 43 80.3125ZM43 51.0938C41.5059 51.0938 40.3438 49.9316 40.3438 48.4375V21.875C40.3438 20.5469 41.5059 19.2188 43 19.2188C44.3281 19.2188 45.6562 20.5469 45.6562 21.875V48.4375C45.6562 49.9316 44.3281 51.0938 43 51.0938ZM43 57.7344C45.1582 57.7344 46.9844 59.5605 46.9844 61.7188C46.9844 64.043 45.1582 65.7031 43 65.7031C40.6758 65.7031 39.0156 64.043 39.0156 61.7188C39.0156 59.5605 40.6758 57.7344 43 57.7344Z" fill="#C11B2F" />
        </svg>
    )
}

export default CircleExclamaion
