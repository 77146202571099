import { Row } from "antd";
import React, { memo } from "react";
import "./CustomRow.less";

function CustomRow(props) {
  return (
    <Row {...props} className={`custom-row ${props.className}`}>
      {props.children}
    </Row>
  );
}

export default memo(CustomRow);
