import React from "react";

function DashboardIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25033 3.66669H4.58366C4.0774 3.66669 3.66699 4.07709 3.66699 4.58335V8.25002C3.66699 8.75628 4.0774 9.16669 4.58366 9.16669H8.25033C8.75659 9.16669 9.16699 8.75628 9.16699 8.25002V4.58335C9.16699 4.07709 8.75659 3.66669 8.25033 3.66669Z"
        stroke="#C11B2F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.417 3.66669H13.7503C13.2441 3.66669 12.8337 4.07709 12.8337 4.58335V8.25002C12.8337 8.75628 13.2441 9.16669 13.7503 9.16669H17.417C17.9233 9.16669 18.3337 8.75628 18.3337 8.25002V4.58335C18.3337 4.07709 17.9233 3.66669 17.417 3.66669Z"
        stroke="#C11B2F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25033 12.8334H4.58366C4.0774 12.8334 3.66699 13.2438 3.66699 13.75V17.4167C3.66699 17.9229 4.0774 18.3334 4.58366 18.3334H8.25033C8.75659 18.3334 9.16699 17.9229 9.16699 17.4167V13.75C9.16699 13.2438 8.75659 12.8334 8.25033 12.8334Z"
        stroke="#C11B2F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.417 12.8334H13.7503C13.2441 12.8334 12.8337 13.2438 12.8337 13.75V17.4167C12.8337 17.9229 13.2441 18.3334 13.7503 18.3334H17.417C17.9233 18.3334 18.3337 17.9229 18.3337 17.4167V13.75C18.3337 13.2438 17.9233 12.8334 17.417 12.8334Z"
        stroke="#C11B2F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DashboardIcon;
