import React from "react";

function InvoicesIcon() {
  return (

    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.344 1.83331C12.602 1.83331 12 3.62398 12 5.83331H13.344C13.992 5.83331 14.3153 5.83331 14.516 5.60998C14.716 5.38598 14.6813 5.09131 14.612 4.50265C14.4267 2.94665 13.9293 1.83331 13.344 1.83331Z" stroke="#A3A3A3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.0002 5.86931V12.9306C12.0002 13.938 12.0002 14.442 11.6922 14.6406C11.1888 14.9646 10.4108 14.2846 10.0195 14.038C9.69616 13.834 9.53483 13.7326 9.3555 13.7266C9.1615 13.72 8.99683 13.818 8.6475 14.038L7.3735 14.8413C7.0295 15.058 6.85816 15.1666 6.66683 15.1666C6.4755 15.1666 6.3035 15.058 5.96016 14.8413L4.68683 14.038C4.36283 13.834 4.2015 13.7326 4.02216 13.7266C3.82816 13.72 3.6635 13.818 3.31416 14.038C2.92283 14.2846 2.14483 14.9646 1.64083 14.6406C1.3335 14.442 1.3335 13.9386 1.3335 12.9306V5.86931C1.3335 3.96665 1.3335 3.01598 1.9195 2.42465C2.50483 1.83331 3.44816 1.83331 5.3335 1.83331H13.3335" stroke="#A3A3A3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.66683 5.83329C5.93016 5.83329 5.3335 6.28129 5.3335 6.83329C5.3335 7.38529 5.93016 7.83329 6.66683 7.83329C7.4035 7.83329 8.00016 8.28129 8.00016 8.83329C8.00016 9.38529 7.4035 9.83329 6.66683 9.83329M6.66683 5.83329C7.24683 5.83329 7.7415 6.11129 7.92416 6.49996M6.66683 5.83329V5.16663M6.66683 9.83329C6.08683 9.83329 5.59216 9.55529 5.4095 9.16663M6.66683 9.83329V10.5" stroke="#A3A3A3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
}

export default InvoicesIcon;
