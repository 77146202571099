import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Layout, Menu, Modal } from "antd";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import CustomButton from "components/UiComponents/CustomButton";
import CustomHeading from "components/UiComponents/CustomHeading";
import CustomRow from "components/UiComponents/CustomRow/index.jsx";
import CustomColumn from "components/UiComponents/CustomColumn/index.jsx";
import { USER_TYPE } from "utils/Constants/Constant";
import {
  AllMenusItems,
  authenticatedRoutes,
  permissionsToRoutes,
  unAuthenticatedRoutes,
} from "utils/Constants/routes.constant";
import { useAuth } from "hooks/useAuth";
import LogoutIcon from "assets/SvgFiles/LogoutIcon";
import CircleExclamaion from "assets/SvgFiles/CircleExclamaion";
import { PageTitleContext } from "PageTitleContext";
import helperFunction from "utils/Helper/Functions.helper";
import authSlice from "../../redux/Slices/auth.Slice";
import userSlice from "../../redux/Slices/user.Slice";
import logo from "assets/Images/jfa-logo.svg";
import { MenuFoldOutlined, CloseOutlined } from "@ant-design/icons";
import "./Header.less";

const { Header } = Layout;

const TopHeader = () => {
  const breadcrumbs = useBreadcrumbs();
  const { pageTitle } = useContext(PageTitleContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { userObj } = useAuth();
  const { logout } = authSlice.actions;
  const { logoutMe } = userSlice.actions;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { confirm } = Modal;
  const [menuOpen, setMenuOpen] = useState(false);

  const handleNavigate = (item) => setMenuOpen(!menuOpen);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Active menu key based on current location
  const activeMenuItemKey = AllMenusItems.find((item) =>
    location.pathname.startsWith(`/${item.linkTo}`)
  )?.selectedOptionKey;

  // Filter breadcrumbs
  const filteredBreadcrumbs = breadcrumbs.filter(({ match }) => {
    const segments = match.pathname.split("/");
    return (
      match.pathname !== unAuthenticatedRoutes.AUTHSCREEN &&
      !segments.some((segment) => /^\d+$/.test(segment))
    );
  });

  // Breadcrumb items
  const breadcrumbItems = filteredBreadcrumbs.map(
    ({ match, breadcrumb }, index) => {
      const isLastBreadcrumb = index === filteredBreadcrumbs.length - 1;
      return {
        key: match.pathname,
        title: isLastBreadcrumb ? (
          breadcrumb
        ) : (
          <Link to={match.pathname}>{breadcrumb}</Link>
        ),
      };
    }
  );

  // Filtering the menu items based on the permissions
  const filterMenuItemsByPermissions = (menuItems, permissions) => {
    if (!Array.isArray(permissions)) {
      return [];
    }
    return menuItems.filter((menuItem) => {
      const route = menuItem.linkTo;
      // Allow access if no requiredPermissions or an empty array
      const requiredPermissions = permissionsToRoutes[route] || [];
      if (requiredPermissions.length === 0) {
        return true;
      }
      return requiredPermissions.some((permission) =>
        permissions.includes(permission)
      );
    });
  };

  const userPermissions = userObj?.permissions || [];
  const filteredMenuItems = filterMenuItemsByPermissions(
    AllMenusItems,
    userPermissions
  );

  const menuItems = [
    ...filteredMenuItems.map((menu) => ({
      key: menu.selectedOptionKey,
      label: (
        <Link to={menu.linkTo}>
          {menu.icon && <span className="menu-icon">{menu.icon}</span>}
          <span>{menu.label}</span>
        </Link>
      ),
    })),
    {
      key: "logout",
      label: (
        <Link to="#" onClick={() => showPromiseConfirm()}>
          <LogoutIcon />
          <span>Logout</span>
        </Link>
      ),
    },
  ];

  const showPromiseConfirm = () => {
    confirm({
      wrapClassName: "logOutModal",
      title: `Are you sure?`,
      width: "455px",
      centered: "true",
      closable: "true",
      destroyOnClose: "true",
      content: t("areYouSureYouWantToLogout"),
      okText: t("logout"),
      cancelText: t("cancel"),
      icon: (
        <div className="icon-wrapper">
          <CircleExclamaion />
        </div>
      ),
      async onOk() {
        logoutCall();
      },
      async onCancel() {},
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <OkBtn />
          <CancelBtn />
        </>
      ),
    });
  };

  const logoutCall = useCallback(async () => {
    helperFunction.deleteLocalStorage("jwtToken");
    helperFunction.deleteLocalStorage("isLoggedIn");
    helperFunction.deleteLocalStorage("orderResponse");

    // Clearing all session storage items
    sessionStorage.clear();

    // dispatch(clearCart());
    dispatch(logout());
    dispatch(logoutMe());

    navigate(`${unAuthenticatedRoutes.AUTHSCREEN}`);
  }, [dispatch, logout, logoutMe, navigate]);

  const items = [
    {
      label: t("productCatalogs"),
      linkTo: permissionsToRoutes.PRODUCT_CATALOGS,
      key: "",
    },
    {
      label: t("orders"),
      linkTo: permissionsToRoutes.ORDERS,
      key: "orders",
    },
    {
      label: t("invoices"),
      linkTo: permissionsToRoutes.INVOICES,
      key: "invoices",
    },
  ];

  // CLEARING SESSION STORAGE IF THE PAGE RELOADS
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      sessionStorage.clear();
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <Header
        className={`headerWrapper ${
          userObj?.userRole === USER_TYPE?.CUSTOMER ? "customer" : "admin"
        }`}
      >
        <CustomRow>
          {/* <CustomColumn xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className="menuWrapper">
              <Link to={permissionsToRoutes.PRODUCT_CATALOGS} className="headerLogo">
                <img src={logo} width={175} height={35} />
              </Link>
              <Menu
                onClick={handleNavigate}
                mode="horizontal"
                defaultSelectedKeys={["2"]}
                items={items}
                style={{ flex: 1, minWidth: 0 }}
              />
            </div>
            <CustomHeading className="title">{pageTitle}</CustomHeading>


            {breadcrumbItems.length > 1 && (
              <Breadcrumb
                separator={BREADCRUMB_SEPARATOR}
                items={breadcrumbItems}
              />
            )}
          </CustomColumn> */}

          <CustomColumn xs={24} sm={24} md={24} lg={24} xl={24}>
            {userObj?.userRole === USER_TYPE?.CUSTOMER && (
              <div className="menuWrapper">
                <Link
                  to={authenticatedRoutes.PRODUCT_CATALOGS}
                  className="headerLogo"
                >
                  <img src={logo} width={137} height={39} />
                </Link>
                <Menu
                  // mode="horizontal"
                  selectedKeys={[activeMenuItemKey]}
                  items={menuItems}
                  className="top-header-menu"
                />
              </div>
            )}
            {userObj?.userRole === USER_TYPE?.ADMIN && (
              <CustomHeading className="title">{pageTitle}</CustomHeading>
            )}
          </CustomColumn>
        </CustomRow>
      </Header>
      {userObj?.userRole === USER_TYPE?.CUSTOMER && (
        <div className="mobileHeaderWrapper">
          <div className="mobileMenuTop">
            <Link
              to={authenticatedRoutes.PRODUCT_CATALOGS}
              className="headerLogo"
            >
              <img src={logo} width={137} height={39} />
            </Link>
            <CustomButton onClick={toggleMenu}>
              <MenuFoldOutlined />
            </CustomButton>
          </div>

          <div className={`headerMenu ${menuOpen ? `open` : ""}`}>
            <div className="mobileMenuTop">
              <CustomButton onClick={toggleMenu}>
                <CloseOutlined />
              </CustomButton>
            </div>
            <Menu
              onClick={handleNavigate}
              defaultSelectedKeys={activeMenuItemKey}
              items={menuItems}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default memo(TopHeader);
