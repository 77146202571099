import React from "react";

function AccountIcon() {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00001 8.08333C9.02505 8.08333 10.6667 6.44171 10.6667 4.41667C10.6667 2.39162 9.02505 0.75 7.00001 0.75C4.97497 0.75 3.33334 2.39162 3.33334 4.41667C3.33334 6.44171 4.97497 8.08333 7.00001 8.08333Z"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 17.25V15.4167C1.5 14.4442 1.88631 13.5116 2.57394 12.8239C3.26158 12.1363 4.19421 11.75 5.16667 11.75H8.83333C9.80579 11.75 10.7384 12.1363 11.4261 12.8239C12.1137 13.5116 12.5 14.4442 12.5 15.4167V17.25"
        stroke="#A3A3A3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default AccountIcon;
