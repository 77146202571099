import authSlice from "./Slices/auth.Slice";
import userSlice from "./Slices/user.Slice";
import cartSlice from "./Slices/cart.Slice";

const Reducers = {
  authSliceReducer: authSlice.reducer,
  userSliceReducer: userSlice.reducer,
  cartSliceReducer: cartSlice.reducer,
};

export default Reducers;
